const CryptoJS = require("crypto-js");
const _secretKey = CryptoJS.enc.Utf8.parse("GftZqNEoBVdS2kwx");
const _ivKey = CryptoJS.enc.Utf8.parse("3zyJFPEzh5rDeUNi");

const AES  = {
    
   /**
     * AES-256-CBC对称加密
     * @param text {string} 要加密的明文
     * @returns {string} 加密后的密文，Base64格式
     */
   AES_CBC_ENCRYPT : function (text) {
        var messageHex = CryptoJS.enc.Utf8.parse(text);
        var encrypted = CryptoJS.AES.encrypt(messageHex, _secretKey, {
        "iv": _ivKey,
        "mode": CryptoJS.mode.CBC,
        "padding": CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },

    /**
     * AES-256-CBC对称解密
     * @param textBase64 {string} 要解密的密文，Base64格式
     * @returns {string} 解密后的明文
     */
    AES_CBC_DECRYPT : function (textBase64) {
        var decrypt = CryptoJS.AES.decrypt(textBase64, _secretKey, {
        "iv": _ivKey,
        "mode": CryptoJS.mode.CBC,
        "padding": CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Utf8.stringify(decrypt);
    }
}

export {
    AES
}